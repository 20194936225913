<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h4 v-if="productId == 4">Reference Check</h4>
              <h4 v-if="productId == 10">Reference Check Validated</h4>
              <div class="underline"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="text-info">Personal details</p>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="firstName"
                  autocomplete="off_firstName"
                  placeholder=" "
                  v-model="step1.first_name"
                />
                <label class="floatingLabel" for="firstName">Given name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="middleName"
                  autocomplete="off_middleName"
                  placeholder=" "
                  v-model="step1.middle_name"
                />
                <label class="floatingLabel" for="firstName">Middle name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="lastName"
                  autocomplete="off_lastName"
                  placeholder=" "
                  v-model="step1.last_name"
                />
                <label class="floatingLabel" for="lastName">Family name</label>
                <div class="invalid-feedback col-12" v-if="errors.last_name">
                  {{ errors.last_name[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  autocomplete="off_mobile_no"
                  placeholder=" "
                  v-model="step1.mobile_no"
                />
                <label class="floatingLabel" for="mobile_no"
                  >Mobile number</label
                >
                <div class="invalid-feedback col-12" v-if="errors.mobile_no">
                  {{ errors.mobile_no[0] }}
                </div>
              </div>
            </div>
          </div>

          <!-- Role Details -->
          <div class="row">
            <div class="col-12 mt-5">
              <p class="text-info">Role Details</p>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  autocomplete="position_title"
                  placeholder=" "
                  v-model="step2.position_title"
                />
                <label class="floatingLabel" for="position_title"
                  >Position title / Occupation</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.position_title"
                >
                  {{ errors.position_title[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  autocomplete="off_employer_name"
                  placeholder=" "
                  v-model="step2.employer_name"
                />
                <label class="floatingLabel" for="employer_name"
                  >Employer name</label
                >
                <div class="invalid-feedback col-12" v-if="errors.location">
                  {{ errors.employer_name[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="location"
                  autocomplete="off_location"
                  placeholder=" "
                  v-model="step2.location"
                />
                <label class="floatingLabel" for="location"
                  >Location / Town</label
                >
                <div class="invalid-feedback col-12" v-if="errors.location">
                  {{ errors.location[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="state"
                  autocomplete="off_state"
                  placeholder=" "
                  v-model="step2.state"
                />
                <label class="floatingLabel" for="state">State</label>
                <div class="invalid-feedback col-12" v-if="errors.state">
                  {{ errors.state[0] }}
                </div>
              </div>
            </div>
          </div>

          <!-- Upload documents -->
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="sits-file-upload_2">
                <div class="upload-view">
                  <div class="mr500">
                    <input
                      type="file"
                      id="actual-btn"
                      ref="file"
                      @change="uploadFile"
                      hidden
                    />
                    <!-- our custom upload button -->
                    <label class="upload-label" for="actual-btn"
                      ><i class="fas fa-cloud-upload-alt text-info"></i> Upload
                      CV</label
                    >

                    <div
                      class="invalid-feedback col-12"
                      v-if="errors.file_doc_urls"
                    >
                      {{ errors.file_doc_urls[0] }}
                    </div>
                  </div>

                  <wdImageAvatarPreview
                    :title="'Character Reference Check'"
                    :coverImgUrl="step2.doc_urls"
                    :fontAwClass="'fas fa-file-alt'"
                    :fontAwSize="'fs-2300'"
                  ></wdImageAvatarPreview>
                </div>
                <!-- name of file chosen -->
                <span v-if="document.name" id="file-chosen">{{
                  document.name
                }}</span>
              </div>
            </div>
          </div>

          <!-- Referee Details -->
          <div class="row" v-if="isSave">
            <div class="col-12 mt-5">
              <p class="text-info mb-0">Referee Details</p>
              <div class="invalid-feedback col-12" v-if="errors.project_id">
                {{ errors.project_id[0] }}
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-12 text-right mb-3">
              <appModalRefereeNew
                :projectId="projectId"
                :productId="productId"
                :propsData="getReference"
              >
              </appModalRefereeNew>
            </div>
            <div class="col-12 table-responsive">
              <table class="table table-bordered table-striped table-sm m-0">
                <thead>
                  <tr>
                    <th>
                      <p class="mb-0">Given Name</p>
                    </th>
                    <th>
                      <p class="mb-0">Family Name</p>
                    </th>
                    <th>Phone number</th>
                    <th>
                      <p class="mb-0">Email</p>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in getReference.reference_trans"
                    :key="index"
                  >
                    <td>{{ row.referee_first_name }}</td>
                    <td>{{ row.referee_last_name }}</td>
                    <td>{{ row.referee_phone_no }}</td>
                    <td>{{ row.referee_email }}</td>
                    <td>
                      <div>
                        <appModalRefereeEdit
                          :projectId="projectId"
                          :productId="productId"
                          :propsData="row"
                          class="d-inline"
                        >
                        </appModalRefereeEdit>
                        <a
                          href="javascript: void(0)"
                          class="p-2 d-inline"
                          @click="fnDeleteReference(row.id)"
                          ><i class="far fa-times-circle text-info"></i
                        ></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Action section -->
          <div class="row mt-3">
            <div class="col-12 text-right">
              <router-link
                v-if="loginUserObj.role_id == 9"
                class="btn btn-secondary btn-sm mr-2"
                :to="{ name: 'dashboardCC' }"
                >Cancel</router-link
              >
              <button
                class="btn btn-info btn-sm mr-2"
                @click="fnUpdate('save')"
              >
                <b-spinner small v-if="loadingSave" class="mr-2"></b-spinner
                >Save
              </button>

              <button
                v-if="isCorporateStaff && isSave"
                class="btn btn-info btn-sm"
                :disabled="!isSubmit"
                @click="fnUpdate('submit')"
              >
                <b-spinner small v-if="loadingSubmit" class="mr-2"></b-spinner>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <div class="card h-overflow">
        <div class="card-body">
          <appStep2Ins></appStep2Ins>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as chkReferenceService from "../../services/chkReference.service";
import * as messageService from "../../services/message.service";
import appStep2Ins from "../policeCheck/step2Ins.vue";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import appModalRefereeNew from "./ModalRefereeNew.vue";
import appModalRefereeEdit from "./ModalRefereeEdit.vue";
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";

export default {
  name: "CheckReference.Com",
  props: ["mode", "projectId", "productId", "userId"],
  components: {
    appStep2Ins,
    appModalRefereeNew,
    appModalRefereeEdit,
    wdImageAvatarPreview,
  },
  data() {
    return {
      loginUserObj: {},
      errors: {},
      stateOfLicence: "",
      loadingSave: false,
      loadingSubmit: false,
      fileRemoveDisabled: true,
      document: "",
      step1: {
        userId: "",
        gender_code: "M",
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_birth: "",
        country_of_birth_code: "Australia",
        email: "",
        mobile_no: "",
        have_a_given_name: "Yes",
        have_a_middle_name: "Yes",
        have_a_family_name: "Yes",
        unit_no: "",
        street_no: "",
        street: "",
        suburb: "",
        state_code: "",
        post_code: "",
        country_code: "",
      },
      step2: {
        id: "", // is references_id
        project_id: "",
        position_title: "",
        employer_name: "",
        location: "",
        state: "",
        status: 1,
      },
      step3: {
        reference_trans: [],
      },
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getReference", "getUserFromId"]),

    isCorporateStaff() {
      if (this.loginUserObj.role_id == 4 || this.loginUserObj.role_id == 5) {
        return true;
      }
      return false;
    },
    isAdminStaff() {
      if (this.loginUserObj.role_id == 1 || this.loginUserObj.role_id == 2) {
        return true;
      }
      return false;
    },
    isSubmit() {
      if (
        this.getReference &&
        this.getReference.status < 2 &&
        this.step3.reference_trans.length > 0
      ) {
        return true;
      }

      return false;
    },
    isSave() {
      if (this.getReference && this.getReference.status >= 1) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions(["fetchReference", "fetchUserFromId"]),

    /**
     * Save / Submit the Data, it will use by the corporate staff and Admin staff
     */
    fnUpdate: async function (mode) {
      try {
        this.step1.userId = this.userId;

        if (this.loginUserObj.role_id == 4 || this.loginUserObj.role_id == 5) {
          if (mode == "save") {
            this.loadingSave = true;
            this.step2.status = 1;
          } else {
            this.loadingSubmit = true;
            this.step2.status = 2;
          }
        }

        const formData = new FormData();
        formData.append("project_id", this.projectId);
        formData.append("product_id", this.productId);
        formData.append("file_doc_urls", this.document);
        formData.append("position_title", this.step2.position_title);
        formData.append("employer_name", this.step2.employer_name);
        formData.append("location", this.step2.location);
        formData.append("state", this.step2.state);
        formData.append("status", this.step2.status);
        formData.append("userId", this.step1.userId);
        formData.append("gender_code", this.step1.gender_code);
        formData.append("first_name", this.step1.first_name);
        formData.append("middle_name", this.step1.middle_name);
        formData.append("last_name", this.step1.last_name);
        formData.append("date_of_birth", this.step1.date_of_birth);
        formData.append(
          "country_of_birth_code",
          this.step1.country_of_birth_code
        );
        formData.append("email", this.step1.email);
        formData.append("mobile_no", this.step1.mobile_no);
        formData.append("have_a_given_name", this.step1.have_a_given_name);
        formData.append("have_a_middle_name", this.step1.have_a_middle_name);
        formData.append("have_a_family_name", this.step1.have_a_family_name);
        formData.append("unit_no", this.step1.unit_no);
        formData.append("street_no", this.step1.street_no);
        formData.append("street", this.step1.street);
        formData.append("suburb", this.step1.suburb);
        formData.append("state_code", this.step1.state_code);
        formData.append("post_code", this.step1.post_code);
        formData.append("country_code", this.step1.country_code);

        const res = await chkReferenceService.add(formData);

        if (res.data.status_code == 200) {
          let obj = {
            projectId: this.projectId,
            productId: this.productId,
          };
          this.fetchReference(obj);
          this.fetchUserFromId(this.userId);

          if (
            (this.loginUserObj.role_id == 4 ||
              this.loginUserObj.role_id == 5) &&
            mode === "submit"
          ) {
            messageService.fnToastSuccess("Submit successfully!");
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgCM",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.productId),
              },
            });
          } else {
            messageService.fnToastSuccess("Saved successfully!");
          }
        }
        this.loadingSave = false;
        this.loadingSubmit = false;
        this.document = "";
        this.errors = {};
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Reference",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Reference",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Reference",
              "Something wrong, please try again!"
            );
            break;
        }
        this.loadingSave = false;
        this.loadingSubmit = false;
      }
    },

    fnDeleteReference: async function (id) {
      try {
        this.errors = {};

        const res = await chkReferenceService.deleteTrans(id);

        if (res.data.status_code == 200) {
          let obj = {
            projectId: this.projectId,
            productId: this.productId,
          };
          this.fetchReference(obj);
        }

        messageService.fnToastSuccess("Reference - Successfully deleted");
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Reference",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Reference",
              "Something wrong"
            );
            break;
        }
      }
    },
    uploadFile() {
      this.document = this.$refs.file.files[0];
    },
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    let obj = {
      projectId: this.projectId,
      productId: this.productId,
    };
    this.fetchReference(obj);
  },

  watch: {
    getUserFromId(newVal) {
      Object.assign(this.step1, newVal);
    },
    getReference: {
      deep: true,
      handler(getReference) {
        Object.assign(this.step2, getReference);
        Object.assign(this.step3.reference_trans, getReference.reference_trans);
      },
    },
  },
};
</script>
