<template>
  <div>
    <!-- Upload documents -->
    <div class="row">
      <div class="col-12">
        <p>Upload certified document</p>

        <div class="accordion" role="tablist">
          <div v-for="(item, index) in referenceTrans" :key="index">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  href="#"
                  v-b-toggle="'accordion-' + index"
                  variant="secondary"
                  class="text-left"
                  >{{ item.referee_first_name }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'accordion-' + index"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <div class="mb-1" :key="index">
                    <checkReferenceUploadCom
                      :propsData="item"
                      :propsData2="propsData2"
                    ></checkReferenceUploadCom>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import * as messageService from "../../services/message.service";
import * as chkReferenceService from "../../services/chkReference.service";
import checkReferenceUploadCom from "../../components/checkReference/checkReferenceUploadCom.vue";
export default {
  name: "checkReferenceCheckList",
  props: {
    propsData1: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    checkReferenceUploadCom,
  },
  data() {
    return {
      fileRemoveDisabled: true,
      document: "",
      isUploading: false,

      getIndex: -1,
      userData: {},

      step1: {
        userId: "",
        gender_code: "M",
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_birth: "",
        country_of_birth_code: "Australia",
        email: "",
        mobile_no: "",
        have_a_given_name: "Yes",
        have_a_middle_name: "Yes",
        have_a_family_name: "Yes",
        unit_no: "",
        street_no: "",
        street: "",
        suburb: "",
        state_code: "",
        post_code: "",
        country_code: "",
      },
      step2: {
        id: "",
        project_id: "",
        product_id: "",
        position_title: "",
        employer_name: "",
        location: "",
        state: "",
        doc_urls: "",
        certificate_urls: "",
        status: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["getReference", "getUserFromId"]),
    referenceTrans() {
      let x = [];
      if (
        this.propsData2 &&
        this.propsData2.reference_trans &&
        this.propsData2.reference_trans.length > 0
      ) {
        x = this.propsData2.reference_trans;
      }

      return x;
    },
  },
  methods: {
    ...mapActions(["fetchReference"]),

    fnUpdateCheckList: async function () {
      this.errors = {};

      try {
        const formData = new FormData();
        formData.append("userId", this.step1.id);
        formData.append("project_id", this.step2.project_id);
        formData.append("product_id", this.step2.product_id);
        formData.append("file_doc_urls", "");
        formData.append("file_certificate_urls", this.document);
        formData.append("position_title", this.step2.position_title);
        formData.append("employer_name", this.step2.employer_name);
        formData.append("location", this.step2.location);
        formData.append("state", this.step2.state);
        formData.append("status", this.step2.status);
        formData.append("gender_code", this.step1.gender_code);
        formData.append("first_name", this.step1.first_name);
        formData.append("middle_name", this.step1.middle_name);
        formData.append("last_name", this.step1.last_name);
        formData.append("date_of_birth", this.step1.date_of_birth);
        formData.append(
          "country_of_birth_code",
          this.step1.country_of_birth_code
        );
        formData.append("email", this.step1.email);
        formData.append("mobile_no", this.step1.mobile_no);
        formData.append("have_a_given_name", this.step1.have_a_given_name);
        formData.append("have_a_middle_name", this.step1.have_a_middle_name);
        formData.append("have_a_family_name", this.step1.have_a_family_name);
        formData.append("unit_no", this.step1.unit_no);
        formData.append("street_no", this.step1.street_no);
        formData.append("street", this.step1.street);
        formData.append("suburb", this.step1.suburb);
        formData.append("state_code", this.step1.state_code);
        formData.append("post_code", this.step1.post_code);
        formData.append("country_code", this.step1.country_code);

        const res = await chkReferenceService.add(formData);

        let obj = {
          projectId: this.propsData2.project_id,
          productId: this.propsData2.product_id,
        };
        this.fetchReference(obj);

        messageService.fnToastSuccess("Reference - Successfully updated");
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Reference",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Reference",
              "Something wrong"
            );
            break;
        }
      }
    },
    uploadFile() {
      this.document = this.$refs.file.files[0];
    },
  },
  watch: {
    getUserFromId(newVal) {
      Object.assign(this.step1, newVal);
    },
    propsData1: {
      deep: true,
      handler(propsData1) {
        Object.assign(this.step1, propsData1);
      },
    },
    propsData2: {
      deep: true,
      handler(propsData2) {
        Object.assign(this.step2, propsData2);
      },
    },
  },
};
</script>
