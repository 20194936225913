<template>
  <div class="card sits-border-info" v-if="propsCheckDetails.id">
    <div class="card-header">
      <h5 class="text-info mb-0">Check details</h5>
    </div>
    <div class="card-body">
      <dl class="row">
        <dd class="col-sm-5 text-black-60 fw-600">Project Id</dd>
        <dd class="col-sm-7">{{ propsCheckDetails.project_id }}</dd>
        <dd class="col-sm-5 text-black-60 fw-600">Check ref.</dd>
        <dd class="col-sm-7">{{ propsCheckDetails.id }}</dd>
        <dd class="col-sm-5 text-black-60 fw-600">Check type</dd>
        <dd class="col-sm-7">
          <span
            v-if="propsCheckDetails.product && propsCheckDetails.product.name"
            >{{ propsCheckDetails.product.name }}</span
          >
        </dd>
        <dd class="col-sm-5 text-black-60 fw-600">Stake holder</dd>
        <dd class="col-sm-7">
          <span
            v-if="
              propsCheckDetails.project &&
              propsCheckDetails.project.stake_holder &&
              propsCheckDetails.project.stake_holder.business_name
            "
          >
            {{ propsCheckDetails.project.stake_holder.business_name }}</span
          >
          <span
            v-else-if="
              propsCheckDetails.project &&
              propsCheckDetails.project.stake_holder &&
              propsCheckDetails.project.stake_holder.first_name
            "
          >
            {{ propsCheckDetails.project.stake_holder.first_name }}</span
          >
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
export default {
  name: "checkDetails",
  props: {
    propsCheckDetails: {
      required: true,
      type: Object,
    },
  },
};
</script>