<template>
  <div>
    <div class="sits-file-upload_2">
      <div class="upload-view">
        <div class="mr500">
          <input
            type="file"
            :id="`actual-btn-${obj.id}`"
            :ref="`file${obj.id}`"
            @change="uploadFile()"
            hidden
          />
          <!-- our custom upload button -->
          <label :class="`upload-label-${obj.id}`" :for="`actual-btn-${obj.id}`"
            ><i class="fas fa-cloud-upload-alt text-info"></i> Select
            document</label
          >
        </div>
        <wdImageAvatarPreview
          :title="'Character Reference Check'"
          :coverImgUrl="propsData.certificate_urls_chk"
          :fontAwClass="'fas fa-file-alt'"
          :fontAwSize="'fs-2300'"
        ></wdImageAvatarPreview>
      </div>
      <!-- name of file chosen -->
      <span v-if="document && document.name" id="file-chosen">{{
        document.name
      }}</span>
    </div>

    <div class="text-right">
      <button class="btn btn-sm btn-info" @click="fnUpdateCheckList()">
        <b-spinner small v-if="isUploading" class="mr-2"></b-spinner>
        Upload
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import * as messageService from "../../services/message.service";
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";
import * as chkReferenceService from "../../services/chkReference.service";
export default {
  name: "checkReferenceUploadCom",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    wdImageAvatarPreview,
  },
  data() {
    return {
      fileRemoveDisabled: true,
      document: "",
      isUploading: false,
      getIndex: -1,
      userData: {},
      obj: {
        id: "",
        references_id: "",
        referee_first_name: "",
        referee_last_name: "",
        referee_phone_no: "",
        referee_email: "",
        certificate_urls_chk: "",
        notes_chk: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getReference", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchReference"]),

    fnUpdateCheckList: async function () {
      this.errors = {};
      this.isUploading = true;
      try {
        const formData = new FormData();
        formData.append("id", this.obj.id);
        formData.append("references_id", this.obj.references_id);
        formData.append("referee_first_name", this.obj.referee_first_name);
        formData.append("referee_last_name", this.obj.referee_last_name);
        formData.append("referee_phone_no", this.obj.referee_phone_no);
        formData.append("referee_email", this.obj.referee_email);
        formData.append("certificate_urls_chk", this.obj.certificate_urls_chk);
        formData.append("file_certificate_urls_chk", this.document);
        formData.append("notes_chk", this.obj.notes_chk);

        const res = await chkReferenceService.updateTrans(
          this.obj.id,
          formData
        );

        let obj = {
          projectId: this.propsData2.project_id,
          productId: this.propsData2.product_id,
        };
        this.fetchReference(obj);

        messageService.fnToastSuccess("Reference - Successfully updated");
        this.document = "";
        this.isUploading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Reference",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Reference",
              "Something wrong"
            );
            break;
        }
        this.isUploading = false;
      }
    },
    uploadFile() {
      this.$nextTick(() => {
        let xx = Object.keys(this.$refs)[0];
        this.document = this.$refs[xx].files[0];
      });
    },
  },
  created() {
    Object.assign(this.obj, this.propsData);
  },
};
</script>
