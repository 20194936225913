var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.propsCheckDetails.id)?_c('div',{staticClass:"card sits-border-info"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('dl',{staticClass:"row"},[_c('dd',{staticClass:"col-sm-5 text-black-60 fw-600"},[_vm._v("Project Id")]),_c('dd',{staticClass:"col-sm-7"},[_vm._v(_vm._s(_vm.propsCheckDetails.project_id))]),_c('dd',{staticClass:"col-sm-5 text-black-60 fw-600"},[_vm._v("Check ref.")]),_c('dd',{staticClass:"col-sm-7"},[_vm._v(_vm._s(_vm.propsCheckDetails.id))]),_c('dd',{staticClass:"col-sm-5 text-black-60 fw-600"},[_vm._v("Check type")]),_c('dd',{staticClass:"col-sm-7"},[(_vm.propsCheckDetails.product && _vm.propsCheckDetails.product.name)?_c('span',[_vm._v(_vm._s(_vm.propsCheckDetails.product.name))]):_vm._e()]),_c('dd',{staticClass:"col-sm-5 text-black-60 fw-600"},[_vm._v("Stake holder")]),_c('dd',{staticClass:"col-sm-7"},[(
            _vm.propsCheckDetails.project &&
            _vm.propsCheckDetails.project.stake_holder &&
            _vm.propsCheckDetails.project.stake_holder.business_name
          )?_c('span',[_vm._v(" "+_vm._s(_vm.propsCheckDetails.project.stake_holder.business_name))]):(
            _vm.propsCheckDetails.project &&
            _vm.propsCheckDetails.project.stake_holder &&
            _vm.propsCheckDetails.project.stake_holder.first_name
          )?_c('span',[_vm._v(" "+_vm._s(_vm.propsCheckDetails.project.stake_holder.first_name))]):_vm._e()])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"text-info mb-0"},[_vm._v("Check details")])])}]

export { render, staticRenderFns }