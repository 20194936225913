<template>
  <div class="card-columns">
    <appCheckDetails :propsCheckDetails="propsData2"></appCheckDetails>
    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Personal details</h5>
      </div>
      <div class="card-body">
        <dl class="row" v-if="propsData1">
          <dd class="col-sm-5 text-black-60 fw-600">Given name</dd>
          <dd class="col-sm-7">
            {{ propsData1.first_name }}
          </dd>
          <dd class="col-sm-5 text-black-60 fw-600">Middle name</dd>
          <dd class="col-sm-7">{{ propsData1.middle_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Family name</dd>
          <dd class="col-sm-7">{{ propsData1.last_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Date of birth</dd>
          <dd class="col-sm-7">{{ propsData1.date_of_birth }}</dd>
        </dl>
      </div>
    </div>
    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Role Details</h5>
      </div>
      <div class="card-body">
        <dl class="row" v-if="propsData2">
          <dd class="col-sm-5 text-black-60 fw-600">Position title</dd>
          <dd class="col-sm-7">
            {{ propsData2.position_title }}
          </dd>
          <dd class="col-sm-5 text-black-60 fw-600">Employer name</dd>
          <dd class="col-sm-7">{{ propsData2.employer_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Location / Town</dd>
          <dd class="col-sm-7">{{ propsData2.location }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">State</dd>
          <dd class="col-sm-7">{{ propsData2.state }}</dd>

          <dd class="col-sm-5 text-black-60 fw-600">Document</dd>
          <dd class="col-sm-7">
            <wdImageAvatarPreview
              :title="'Document'"
              :coverImgUrl="propsData2.doc_urls"
              :fontAwClass="'fas fa-file-alt'"
            ></wdImageAvatarPreview>
          </dd>
        </dl>
      </div>
    </div>

    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Referees</h5>
      </div>

      <div class="card-body">
        <div class="accordion" role="tablist">
          <div v-for="(item, index) in referenceTrans" :key="index">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  href="#"
                  v-b-toggle="'accordion-' + index"
                  variant="secondary"
                  class="text-left"
                  >{{ item.referee_first_name }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'accordion-' + index"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text v-html="item.notes_chk"></b-card-text>
                  <div class="mb-1" :key="index">
                    <dl class="row">
                      <dd class="col-sm-5 text-black-60 fw-600">First name</dd>
                      <dd class="col-sm-7">
                        {{ item.referee_first_name }}
                      </dd>
                      <dd class="col-sm-5 text-black-60 fw-600">Last name</dd>
                      <dd class="col-sm-7">{{ item.referee_last_name }}</dd>
                      <dd class="col-sm-5 text-black-60 fw-600">Phone no</dd>
                      <dd class="col-sm-7">
                        {{ item.referee_phone_no }}
                      </dd>
                      <dd class="col-sm-5 text-black-60 fw-600">Email</dd>
                      <dd class="col-sm-7">
                        {{ item.referee_email }}
                      </dd>
                    </dl>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appCheckDetails from "../../components/project/checkDetails.vue";
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";
export default {
  name: "checkReferenceDetail",
  props: {
    propsData1: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    appCheckDetails,
    wdImageAvatarPreview,
  },
  computed: {
    referenceTrans() {
      let x = [];
      if (
        this.propsData2 &&
        this.propsData2.reference_trans &&
        this.propsData2.reference_trans.length > 0
      ) {
        x = this.propsData2.reference_trans;
      }

      return x;
    },
  },
};
</script>