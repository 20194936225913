<template>
  <div>
    <a
      href="javascript: void(0)"
      data-toggle="tooltip"
      data-placement="top"
      title="Edit referee"
      @click="fnShowModal()"
      ><i class="far fa-edit text-info"></i
    ></a>
    <b-modal
      ref="refereeModal"
      size="lg"
      hide-footer
      title="Edit Referee details"
    >
      <div class="d-block">
        <form v-on:submit.prevent="fnUpdate">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <label for="firstName">First name </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First name"
                      v-model="userData.referee_first_name"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="errors.referee_first_name"
                    >
                      {{ errors.referee_first_name[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <label for="lastName">Family name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      placeholder="Family name"
                      v-model="userData.referee_last_name"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="errors.referee_last_name"
                    >
                      {{ errors.referee_last_name[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <label for="lastName">Phone no</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      placeholder="Phone no"
                      v-model="userData.referee_phone_no"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="errors.referee_phone_no"
                    >
                      {{ errors.referee_phone_no[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <label class="">Email</label>
                    <input
                      class="form-control"
                      type="text"
                      autocomplete="off_date_of_change"
                      placeholder="Email"
                      v-model="userData.referee_email"
                    />
                    <div class="invalid-feedback" v-if="errors.referee_email">
                      {{ errors.referee_email[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-info btn-sm">
              <b-spinner small v-if="isLoadingSave" class="mr-2"></b-spinner
              >Save
            </button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as chkReferenceService from "../../services/chkReference.service";

export default {
  name: "ModalRefereeEdit",
  props: ["projectId", "productId", "propsData"],
  data() {
    return {
      isLoadingSave: false,
      errors: {},
      userData: {
        referee_first_name: "",
        referee_last_name: "",
        referee_phone_no: "",
        referee_email: "",
      },
    };
  },

  methods: {
    ...mapActions(["fetchReference"]),
    fnShowModal() {
      this.showModal();
    },
    hideModal() {
      this.$refs.refereeModal.hide();
    },
    showModal() {
      if (this.propsData) {
        this.userData = this.propsData;
      }

      this.$refs.refereeModal.show();
    },
    fnUpdate: async function () {
      this.isLoadingSave = true;
      this.errors = {};

      try {
        this.errors = {};

        await chkReferenceService.updateTrans(this.userData.id, this.userData);

        let obj = {
          projectId: this.projectId,
          productId: this.productId,
        };

        this.fetchReference(obj);
        this.isLoadingSave = false;
        this.hideModal();

        messageService.fnToastSuccess("Referee details - Successfully updated");

        this.isLoadingSave = false;

        this.userData = {
          referee_first_name: "",
          referee_last_name: "",
          referee_phone_no: "",
          referee_email: "",
        };
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Referee details",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Referee details",
              "Something wrong"
            );
            break;
        }

        this.isLoadingSave = false;
      }
    },
  },
};
</script>