<template>
  <div>
    <span v-if="status == 1" class="badge badge-secondary">Draft</span>
    <span v-if="status == 2" class="badge badge-primary">New</span>
    <span v-if="status == 3" class="badge badge-warning">MIR</span>
    <span v-if="status == 4" class="badge badge-info">WFPR</span>
    <span v-if="status == 5" class="badge badge-success">Completed</span>
    <span v-if="status == 6" class="badge badge-dark">Cancelled</span>
    <span v-if="status == 7" class="badge badge-light">Ready to Check</span>
  </div>
</template>
<script>
export default {
  name: "WDStatusBadge",
  props: ["status"],
  data() {
    return {};
  },
};
</script>