<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div>
          <dl class="row">
            <dd class="col-sm-5 fw-700">Current Status</dd>
            <dd class="col-sm-7">
              <div v-if="status">
                <span v-if="status == 1" class="badge badge-secondary"
                  >Draft</span
                >
                <span v-if="status == 2" class="badge badge-primary">New</span>
                <span v-if="status == 3" class="badge badge-warning">MIR</span>
                <span v-if="status == 7" class="badge badge-light"
                  >Ready to Check</span
                >
                <span v-if="status == 4" class="badge badge-info">WFPR</span>
                <span v-if="status == 5" class="badge badge-success"
                  >Completed</span
                >
                <span v-if="status == 6" class="badge badge-dark"
                  >Cancelled</span
                >
              </div>
            </dd>
            <dd class="col-sm-5 fw-700">Change Status</dd>
            <dd class="col-sm-7">
              <div class="form-group">
                <select class="form-control" v-model="userData.new_status">
                  <option
                    v-for="item in statusOptions"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </dd>
          </dl>
        </div>

        <div class="text-right">
          <button class="btn btn-sm btn-info" @click="emitCustomEvent()">
            <b-spinner small v-if="isLoadingSave" class="mr-2"></b-spinner>
            Save
          </button>
        </div>
      </div>
    </div>

    <!-- Before sending Email notification confirmation -->
    <div>
      <b-modal
        ref="checkConfirmationModal"
        hide-footer
        :title="`Send an email notification`"
      >
        <div class="d-block">
          <form v-on:submit.prevent="fnSendNotificationIsStatusChange()">
            <p>
              Do you want to send a
              <strong>{{ getProductName }}</strong> Certificate
              {{ getStatusName }} email?
              {{ userData.id }}
            </p>
            <hr />
            <div class="text-right">
              <button
                type="button"
                class="btn btn-secondary btn-sm mr-2"
                @click="hideModal()"
              >
                No
              </button>
              <button type="submit" class="btn btn-info btn-sm">
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>Yes
              </button>
            </div>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as notificationService from "../../services/notification.service";

export default {
  name: "StatusChange",
  props: {
    id: {
      type: Number,
      required: true,
      default: -1,
    },
    projectId: {
      type: Number,
    },
    productId: {
      type: Number,
      required: true,
      default: -1,
    },
    status: {
      type: Number,
      required: true,
      default: -1,
    },
  },

  data() {
    return {
      userData: {
        id: -1,
        project_id: -1,
        product_id: -1,
        new_status: -1,
      },
      isLoadingSave: false,
      loading: false,
      statusOptions: [
        { id: 1, name: "Draft" },
        { id: 2, name: "New" },
        { id: 3, name: "More Information Required" },
        { id: 7, name: "Ready to Check" },
        { id: 4, name: "Waiting For Police Review" },
        { id: 5, name: "Completed" },
        { id: 6, name: "Cancelled" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getProductList"]),
    /**
     * Get Product name
     */
    getProductName: function () {
      const arrProducts = this.getProductList;

      const results = arrProducts.filter((obj) => {
        return obj.id === this.userData.product_id;
      });

      return results[0]?.product_category;
    },
    /**
     * Get Status name
     */
    getStatusName: function () {
      const arrStatus = this.statusOptions;

      const results = arrStatus.filter((obj) => {
        return obj.id == this.userData.new_status;
      });
      return results[0]?.name;
    },
  },
  methods: {
    ...mapActions(["fetchProductList"]),

    emitCustomEvent() {
      this.isLoadingSave = true;
      this.userData.id = this.id;
      this.userData.project_id = this.projectId;
      this.userData.product_id = this.productId;
      this.$emit("fnUpdateStatus", this.userData);

      /**
       * Only show popup
       */
      if (this.userData.new_status == 4 || this.userData.new_status == 5) {
        this.showModal();
      }

      this.isLoadingSave = false;
    },
    hideModal() {
      this.$refs.checkConfirmationModal.hide();
    },
    showModal() {
      this.fetchProductList();
      this.$refs.checkConfirmationModal.show();
    },

    // Send Notification IsStatus Change
    fnSendNotificationIsStatusChange: async function () {
      try {
        this.errors = {};
        this.loading = true;

        await notificationService.isCheckStatusChange(this.id, this.productId);
        this.hideModal();
        messageService.fnToastSuccess("Notification - Successfully sent");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Notification",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Notification",
              "Something wrong"
            );
            break;
        }

        console.log("fnSendNotificationIsStatusChange", error);
      }
    },
  },
  watch: {
    status() {
      this.userData.new_status = this.status;
    },
  },
};
</script>
